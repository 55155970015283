import { useStaticQuery, graphql } from "gatsby";

export default function useNavEntryQuery() {
  const internalEntries = useStaticQuery(graphql`
    query MyQuery {
      pages: allSanityPage {
        nodes {
          menuEntry
          slug {
            current
          }
          title {
            de
            en
          }
        }
      }
    }
  `);

  internalEntries.pages.nodes.forEach((entry) => {
    entry.to = entry.slug.current;
  });

  return internalEntries.pages.nodes;
}
