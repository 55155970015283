import React from "react";
import PropTypes from "prop-types";

import { StoreProvider } from "./StoreContext";
import { UIProvider } from "./UIContext";

const CombinedContextProvider = ({ children }) => {
  return (
    <StoreProvider>
      <UIProvider>{children}</UIProvider>
    </StoreProvider>
  );
};

CombinedContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default CombinedContextProvider;
