import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import {
  Box,
  Link,
  Image,
  Grid,
  GridItem,
  HStack,
  Button,
  Icon,
  Flex,
} from "@chakra-ui/react";
import { LocalizedLink } from "features/navigation";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { RiArrowUpLine } from "react-icons/ri";

import useTranslation from "utils/translate";

const Footer = ({ ...cssprops }) => {
  const t = useTranslation();
  const companyData = useStaticQuery(footerQuery).companyData;
  const pages = useStaticQuery(footerQuery).pages;

  // creating links for pages in footer
  // might be worth simply doing manual in case there are issues
  const FIRST_ORDER_SM = 1;
  const FIRST_ORDER_LG = 1;

  const footerLinksData = pages.nodes.filter(
    (page) => page.menuEntry === "footer"
  );

  let order_sm = FIRST_ORDER_SM;
  let order_lg = FIRST_ORDER_LG;
  const footerLinks = footerLinksData.map((footerLinkObj) => {
    const elem = (
      <GridItem
        order={{ base: order_sm, lg: order_lg }}
        key={footerLinkObj.slug.current}
      >
        <LocalizedLink to={"/" + footerLinkObj.slug.current}>
          {t(footerLinkObj.title)}
        </LocalizedLink>
      </GridItem>
    );

    order_sm += 1;
    order_lg = order_lg === 1 ? 0 : 1; // swapping back and forth beween 0 and 1

    return elem;
  });

  return (
      <Flex
        flexDirection="column"
        {...cssprops}
        minHeight="100vh"
        fontSize={["sm", null, "md"]}
      >
        <Box flexGrow="1" />
        <Box
          flexGrow="0"
          flexShrink="0"
          position="sticky"
          bottom="var(--page-margin-y)"
        >
          <Image
            as={GatsbyImage}
            image={getImage(companyData.formfeldTextLogo.asset.gatsbyImageData)}
            imgStyle={{ objectFit: "contain", objectPosition: "0% 100%" }}
            minHeight="25vw"
            alt="Formfeld Text-Logo"
            mb="1.5rem"
            width="100%"
          />
          <HStack justifyContent="space-between" alignItems="stretch">
            <Grid
              templateColumns={{ base: "repeat(2, auto)", lg: "repeat(4, auto)" }}
              columnGap={["0.5rem", null, "3.5rem"]}
            >
              <GridItem order={{ base: 0, lg: 0 }}>
                {companyData.address.street} {companyData.address.streetNumber}
              </GridItem>
              <GridItem order={{ base: 1, lg: 1 }}>
                {companyData.address.countryCode}-{companyData.address.zipCode}{" "}
                {companyData.address.city}
              </GridItem>
              <GridItem order={{ base: 2, lg: 0 }}>
                <Link href={"tel:" + companyData.telephone}>
                  T {companyData.telephone}
                </Link>
              </GridItem>
              <GridItem order={{ base: 3, lg: 1 }}>
                <Link href={"mailto:" + companyData.mail}>
                  E {companyData.mail}
                </Link>
              </GridItem>
              <GridItem order={{ base: 0, lg: 0 }}>
                <Link isExternal href={companyData.instagramRef}>
                  {companyData.instagramHandle}
                </Link>
              </GridItem>
              {footerLinks}
            </Grid>
            <Flex justifyContent="flex-end" alignItems="flex-end" minWidth="3ex">
              <Button
                aria-label="Go to top of page"
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
                variant="text-button"
              >
                <Icon h="1.5em" fontSize="inherit" as={RiArrowUpLine} />
              </Button>
            </Flex>
          </HStack>
        </Box>
      </Flex>
  );
};

export default Footer;

export const footerQuery = graphql`
  {
    companyData: sanityCompanyInformation {
      instagramHandle
      instagramRef
      formfeldTextLogo {
        asset {
          gatsbyImageData(placeholder: NONE)
        }
      }
      address {
        countryCode
        street
        streetNumber
        zipCode
        city
      }
      mail
      telephone
    }
    pages: allSanityPage {
      nodes {
        title {
          de
          en
        }
        slug {
          current
        }
        menuEntry
      }
    }
  }
`;
