import { extendTheme } from "@chakra-ui/react";
import global from "./globalStyles";

const Button = {
  baseStyle: {
    cursor: "pointer",
    fontWeight: "inherit",
  },
  sizes: {},
  variants: {
    "text-button": {
      fontSize: "inherit",
      fontWeight: "inherit",
      p: 0,
      m: 0,
      height: "auto",
      minWidth: "unset",
    },
    "blocky-button": {
      py: "0.5rem",
      textAlign: "center",
      borderRadius: "0",
      bg: "black",
      color: "white",
    },
  },
};

const Heading = {
  baseStyle: {
    p: 0,
    m: 0,
    fontWeight: "inherit",
  },
  sizes: {
    md: {
      fontSize: "inherit",
      mt: "1rem",
      mb: "1rem",
      _first: {
        mt: "0",
      },
    },
    lg: {
      fontSize: "inherit",
      mt: "28",
      mb: "1em",
    },
    xl: {
      fontSize: "xl",
      mb: "1.25rem",
    },
    "2xl": {
      fontSize: "2xl",
      mb: "3rem",
    },
  },
  variants: {
    navLink: {
      lineHeight: "1em",
    },
    lineTight: {
      lineHeight: "1em"
    }
  },
};

const Text = {
  baseStyle: {
    color: "inherit",
    fontWeight: "inherit",
    fontSize: "inherit",
    _hover: { textDecoration: "none" }
  },
  sizes: {},
};

const Modal = {
  baseStyle: {
    dialog: {
      bg: "#000",
      color: "white",
    },
  },
};

const colors = {
  gray: "#ADADAD",
};

const Link = {
  baseStyle: {
    color: "inherit",
    fontWeight: "inherit",
    fontSize: "inherit",
    _active: { 
      textDecoration: "underline",
    },
  },
  sizes: {},
  variants: {
    highlightedLink: {
      textDecoration: "underline",
    },
    noUnderline: {
      _hover: { 
        textDecoration: "none",
        p: {
          textDecoration: "underline",
        }
      },
    },
  },
};

const theme = {
  styles: {
    global: global,
  },
  colors,
  fontSizes: {
    sm: "0.8rem",
    md: "1rem",
    lg: "1.8rem",
    xl: "3rem",
    "2xl": "4.5rem",
    "3xl": "5.5rem",
  },
  fonts: {
    heading: "Basel Grotesk",
    body: "Basel Grotesk",
  },
  components: {
    Heading,
    Modal,
    Text,
    Link,
    Button,
  },
};

export default extendTheme(theme);
