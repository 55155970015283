import React from "react";
import PropTypes from "prop-types";

const PageContext = React.createContext({});

export const PageContextProvider = ({ value, children }) => {
  const lang = value.pageContext.lang ? value.pageContext.lang : "de";
  return (
    <PageContext.Provider value={{ lang, ...value.pageContext }}>
      {children}
    </PageContext.Provider>
  );
};

const usePageContext = () => React.useContext(PageContext);

PageContextProvider.propTypes = {
  value: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
};

export default usePageContext;
