import React from "react";
import { BaseLayout, DocumentHead } from "./src/features/rootLayout";
import "focus-visible/dist/focus-visible";

import CombinedContextProvider from "./src/context/CombinedContextProvider";
import { PageContextProvider } from "./src/context/PageContext";

export const wrapRootElement = ({ element }) => {
  return <CombinedContextProvider>{element}</CombinedContextProvider>;
};

export const wrapPageElement = ({ props, element }) => {
  return (
    <PageContextProvider value={{ pageContext: props.pageContext }}>
      <DocumentHead />
      <BaseLayout {...props}>{element}</BaseLayout>
    </PageContextProvider>
  );
};

export const onRenderBody = ({ setHeadComponents }) => {
  setHeadComponents([
    <script
      key="tinyanalytics"
      defer
      src="https://app.tinyanalytics.io/pixel/6eqqjRRx4Fml0z52"
    ></script>,
  ]);
};
