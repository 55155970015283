import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import usePageContext from "context/PageContext";
import { SUPPORTED_LANGUAGES } from "utils/languageConfig.mjs";

const DocumentHead = () => {
  const { metaData } = useStaticQuery(query);
  const { lang, unlocalizedSlug } = usePageContext();

  const host = "www.formfeld.net";
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      link={[
        {
          rel: "canonical",
          href: `${host}/${lang}${unlocalizedSlug}`,
        },
        {
          rel: "alternate",
          hrefLang: "x-default",
          href: `${host}${unlocalizedSlug}`,
        },
        ...SUPPORTED_LANGUAGES.map((supportedLang) => ({
          rel: "alternate",
          hrefLang: supportedLang,
          href: `${host}/${supportedLang}${unlocalizedSlug}`,
        })),
      ]}
    >
      <title>{metaData.title}</title>
      <meta name="description" content={metaData.description} />

      {/*FAVICON*/}

      <meta rel="icon" href={metaData.favicon.asset.url} />
      <link rel="icon" href={metaData.favicon.asset.url} />

      {/* SOCIAL MEDIA: Open Graph Tags */}
      {metaData.title && <meta property="og:title" content={metaData.title} />}
      {metaData.description && (
        <meta property="og:description" content={metaData.description} />
      )}
      <meta property="og:locale" content={lang} />
    </Helmet>
  );
};

export default DocumentHead;

const query = graphql`
  query SeoQuery {
    metaData: sanitySiteSettings {
      description
      title
      favicon {
        asset {
          url
        }
      }
    }
  }
`;
