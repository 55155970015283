import React from "react";
import PropTypes from "prop-types";
import { Link, Flex, Button, Box, HStack, Circle } from "@chakra-ui/react";
import { LocalizedLink } from "./LocalizedLink";
import { LanguagePicker } from "./LanguagePicker";
import useUI from "context/UIContext";
import useStore from "context/StoreContext";

export const Navbar = ({
  displayLangPickerOnMobile = "inline",
  displayCartOnMobile = "inline",
  ...rest
}) => {
  const { navOnOpen, navOnClose, navIsOpen } = useUI();
  const { countItemsInCart } = useStore();

  const handleNavTrigger = () => {
    if (navIsOpen) {
      navOnClose();
    } else {
      navOnOpen();
    }
  };

  return (
      <Flex
        boxSizing="border-box"
        height="var(--header-height)"
        justifyContent="space-between"
        alignItems="center"
        {...rest}
      >
        <Box>
          <Link
            pointerEvents="auto"
            as={LocalizedLink}
            to="/"
            onClick={navOnClose}
            size="sm"
          >
            Formfeld
          </Link>
        </Box>
        <HStack spacing="3ex">
          <LanguagePicker
            display={{ base: displayLangPickerOnMobile, lg: "initial" }}
          />
          <LocalizedLink
            to={"/cart"}
            display={{ base: displayCartOnMobile, lg: "initial" }}
            opacity={countItemsInCart() <= 0 && "0"}
            pointerEvents={countItemsInCart() <= 0 && "none"}
          >
            Cart - {countItemsInCart()}
          </LocalizedLink>

          <Button
            onClick={handleNavTrigger}
            color="inherit"
            aria-label="Menu"
            variant="text-button"
          >
            {navIsOpen ? (
              <Circle
                size="0.9em"
                bg="transparent"
                border="2px"
                borderColor="white"
              />
            ) : (
              <Circle size="0.9em" bg="black" border="2px" borderColor="black" />
            )}
          </Button>
        </HStack>
      </Flex>
  );
};

Navbar.propTypes = {
  displayLangPickerOnMobile: PropTypes.oneOf(["inline", "none"]),
  displayCartOnMobile: PropTypes.oneOf(["inline", "none"]),
};

export default Navbar;
