import React, { createContext, useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import Client from "shopify-buy";
import fetch from "isomorphic-fetch";

const client = Client.buildClient(
  {
    domain: process.env.GATSBY_SHOPIFY_STORE_URL,
    storefrontAccessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_TOKEN,
    language: "de-DE"
  },
  fetch
);

const defaultValues = {
  loading: false,
  addLineItem: () => {},
  removeLineItem: () => {},
  client,
  checkout: {
    id: "",
    lineItems: [],
    webUrl: "",
  },
};

const StoreContext = createContext(defaultValues);

const isBrowser = typeof window !== `undefined`;
const localStorageKey = `shopify_checkout_id`;

export const StoreProvider = ({ children }) => {
  const [checkout, setCheckout] = useState(defaultValues.checkout);
  const [loading, setLoading] = useState(false);

  // helper function that combines 1) writing checkout to state // writing checkout to local storage
  const setCheckoutItem = (checkout) => {
    if (isBrowser) {
      localStorage.setItem(localStorageKey, checkout.id);
    }

    setCheckout(checkout);
  };

  // Intializing Checkouts
  useEffect(() => {
    const initializeCheckout = async () => {
      setLoading(true);
      const existingCheckoutID = isBrowser // check if there is a checkout saved in localStorage
        ? localStorage.getItem(localStorageKey)
        : null;

      if (existingCheckoutID && existingCheckoutID !== `null`) {
        try {
          const existingCheckout = await client.checkout.fetch(
            existingCheckoutID
          );
          console.info("Found existing Checkout in local Storage. Loading..");
          if (!existingCheckout.completedAt) {
            setCheckoutItem(existingCheckout);

            setLoading(false);
            return; // successfully loaded state from localStorage
          }
        } catch (e) {
          localStorage.setItem(localStorageKey, null);
        }
      }

      console.info(
        "No checkout found in local Storage. Creating new Checkout.."
      );
      const newCheckout = await client.checkout.create();
      setCheckoutItem(newCheckout);
      setLoading(false);
    };

    initializeCheckout();
  }, []);

  const logCart = () => {
    console.log(checkout.lineItems);
  };

  const clearCart = async () => {
    const newCheckout = await client.checkout.create();
    setCheckoutItem(newCheckout);
  };

  const countItemsInCart = () => checkout.lineItems.length;

  const addLineItem = (variantId, quantity, structureId = "") => {
    setLoading(true);

    const checkoutID = checkout.id;

    const lineItemsToUpdate = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
        customAttributes: [{ key: "structureId", value: structureId }],
      },
    ];

    return client.checkout
      .addLineItems(checkoutID, lineItemsToUpdate)
      .then((res) => {
        setCheckout(res);
        setLoading(false);
      });
  };

  const removeLineItem = (checkoutID, lineItemID) => {
    setLoading(true);

    return client.checkout
      .removeLineItems(checkoutID, [lineItemID])
      .then((res) => {
        setCheckout(res);
        setLoading(false);
      });
  };

  const updateLineItem = (checkoutID, lineItemID, quantity) => {
    setLoading(true);

    const lineItemsToUpdate = [
      { id: lineItemID, quantity: parseInt(quantity, 10) },
    ];

    return client.checkout
      .updateLineItems(checkoutID, lineItemsToUpdate)
      .then((res) => {
        setCheckout(res);
        setLoading(false);
      });
  };

  return (
    <StoreContext.Provider
      value={{
        addLineItem,
        removeLineItem,
        updateLineItem,
        countItemsInCart,
        logCart,
        clearCart,
        checkout,
        loading,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

StoreProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useStore = () => {
  const context = useContext(StoreContext);

  if (context === undefined) {
    throw new Error("useStore must be used within StoreContext");
  }

  return context;
};

export default useStore;
