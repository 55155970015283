import React from "react";
import PropTypes from "prop-types";

import {
  VStack,
  HStack,
  Box,
  Link,
  Text,
  Modal,
  ModalOverlay,
  ModalFooter,
  ModalContent,
  ModalBody,
  useBreakpointValue,
} from "@chakra-ui/react";

import { useStaticQuery, graphql } from "gatsby";
import { LocalizedLink } from "./LocalizedLink";
import useUI from "context/UIContext";
import useTranslation from "utils/translate";
import useNavEntryQuery from "./useNavEntryQuery";
import { STATIC_FIRST_LEVEL_LINKS, STATIC_SECOND_LEVEL_LINKS } from "./config";
import Navbar from "./Navbar";

export const OverlayNav = () => {
  const t = useTranslation();
  const { navIsOpen, navOnClose } = useUI();
  const mainLinkFontSize = useBreakpointValue({
    base: "xl",
    lg: "2xl",
  });
  const sanityEntries = useNavEntryQuery();
  const firstLevelEntries = STATIC_FIRST_LEVEL_LINKS.concat(
    sanityEntries.filter((entry) => entry.menuEntry === "mainEntries")
  );

  const secondLevelEntries = STATIC_SECOND_LEVEL_LINKS.concat(
    sanityEntries.filter((entry) => entry.menuEntry === "secondLevelEntries")
  );

  const companyData = useStaticQuery(graphql`
    query companyQueryB {
      sanityCompanyInformation {
        instagramHandle
        instagramRef
        mail
        telephone
      }
    }
  `).sanityCompanyInformation;

  return (
    <Modal isOpen={navIsOpen} onClose={navOnClose} size="full" bg="black">
      <ModalOverlay />
      <ModalContent px="var(--page-margin-x)" borderRadius="0">
        <ModalBody p="0" display="flex" flexDirection="column">
          <Navbar displayCartOnMobile="none" />
          <VStack
            spacing="0"
            alignItems="flex-start"
            justifyContent="center"
            flexGrow="1"
          >
            {firstLevelEntries.map((navEntry) => (
              <Link
                key={navEntry.to}
                as={LocalizedLink}
                to={"/" + navEntry.to}
                lineHeight="1em"
                fontSize={mainLinkFontSize}
              >
                {t(navEntry.title)}
              </Link>
            ))}
            {/* Only show this on mobile (on desktop it is in the modal footer) */}
            <Box pt="3em" display={{ base: "block", lg: "none" }}>
              <GetInTouchTeaser companyData={companyData} />
              <Link isExternal href={companyData.instagramRef}>
                {companyData.instagramHandle}
              </Link>
            </Box>
          </VStack>
        </ModalBody>

        <ModalFooter px="0">
          <HStack width="100%" spacing="0">
            <HStack
              pr="8ex"
              width="50%"
              display={{ base: "none", lg: "flex" }}
              justifyContent="flex-start"
              spacing="8ex"
            >
              <Box>
                <GetInTouchTeaser companyData={companyData} />
              </Box>
              <Box>
                <Link isExternal href={companyData.instagramRef}>
                  {companyData.instagramHandle}
                </Link>
              </Box>
            </HStack>
            <HStack
              width={{ base: "100%", md: "50%" }}
              spacing={["2ex", "5ex", "8ex"]}
              flexShrink="0"
              justifyContent={{
                base: "space-between",
                md: "flex-start",
                lg: "flex-end",
              }}
            >
              {secondLevelEntries.map((navEntry) => (
                <Link
                  key={navEntry.to}
                  as={LocalizedLink}
                  to={"/" + navEntry.to}
                >
                  {t(navEntry.title)}
                </Link>
              ))}
            </HStack>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

OverlayNav.propTypes = {};

const GetInTouchTeaser = ({ companyData }) => (
  <Text>
    Get in touch via{" "}
    <Link href={"mailto:" + companyData.mail} textDecoration="underline">
      email
    </Link>{" "}
    or{" "}
    <Link
      display={{ base: "block", lg: "inline" }}
      href={"tel:" + companyData.telephone}
    >
      T {companyData.telephone}
    </Link>
  </Text>
);

GetInTouchTeaser.propTypes = {
  companyData: PropTypes.object,
};
