import React from "react";
import { Link } from "@chakra-ui/react";
import { Link as GatsbyLink } from "gatsby";
import usePageContext from "context/PageContext";
import useUI from "context/UIContext";

export const LocalizedLink = ({ to, ...rest }) => {
  const { lang } = usePageContext();
  const { navOnClose } = useUI();

  return (
    <Link as={GatsbyLink} onClick={navOnClose} to={`/${lang}${to}`} {...rest} />
  );
};
