import React, { createContext, useContext } from "react";
import PropTypes from "prop-types";

import { useDisclosure } from "@chakra-ui/react";

export const UIContext = createContext({});

export const UIProvider = ({ children }) => {
  const {
    isOpen: navIsOpen,
    onOpen: navOnOpen,
    onClose: navOnClose,
  } = useDisclosure();

  return (
    <UIContext.Provider
      value={{
        navIsOpen,
        navOnOpen,
        navOnClose,
      }}
    >
      {children}
    </UIContext.Provider>
  );
};

UIProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useUI = () => {
  const context = useContext(UIContext);

  if (context === undefined) {
    throw new Error("useUI must be used within UIContext");
  }

  return context;
};

export default useUI;
