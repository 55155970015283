import React from "react";
import { Box } from "@chakra-ui/layout";

import { OverlayNav, Navbar } from "features/navigation";
import { Footer } from ".";

export const BaseLayout = ({ children }) => {
  return (
    <Box
      position="relative"
      py="var(--page-margin-y)"
      px="var(--page-margin-x)"
      pt="0"
      m={0}
    >
      <OverlayNav px={10} />
      <Box minHeight="100vh">
        {/* pt=0, weil in Header beinhaltet */}
        <Navbar displayLangPickerOnMobile="none" top="0" />
        <Box flexGrow="1">{children}</Box>
        <Footer mt="7rem" />
      </Box>
    </Box>
  );
}; 
