import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { Link } from "@chakra-ui/react";
import usePageContext from "context/PageContext";

export const LanguagePicker = ({ ...cssprops }) => {
  let { unlocalizedSlug, lang } = usePageContext();

  if (!unlocalizedSlug) {
    unlocalizedSlug = "";
  }
  const nonActiveLang = lang === "en" ? "de" : "en";

  const slug = unlocalizedSlug.startsWith("/") // removing leading slash if it exists
    ? unlocalizedSlug.substr(1)
    : unlocalizedSlug;

  return (
    <Link
      textTransform="uppercase"
      as={GatsbyLink}
      key={nonActiveLang}
      aria-label={`Change language to ${nonActiveLang}`}
      to={`/${nonActiveLang}/${slug}`}
      {...cssprops}
    >
      {nonActiveLang}
    </Link>
  );
};
