import usePageContext from "context/PageContext.js";
import { DEFAULT_LANGUAGE } from "./languageConfig.mjs";

const useTranslation = () => {
  const { lang } = usePageContext();
  return translatorFactory(lang);
};

export const useTranslationForRaw = () => {
  const { lang } = usePageContext();
  return translatorFactory(
    rawifyLangTag(lang),
    rawifyLangTag(DEFAULT_LANGUAGE)
  );
};

const rawifyLangTag = (langTag) => `_raw${capitalizeFirstLetter(langTag)}`;

const capitalizeFirstLetter = (inputString) =>
  inputString.charAt(0).toUpperCase() + inputString.slice(1);

const translatorFactory = (lang, defaultLanguage = DEFAULT_LANGUAGE) => {
  return (node) => parseTranslationNode(node, lang, defaultLanguage);
};

/**
 * Returns the string in node for the specified lang
 * @param {object || string} node: An object containing strings for multiple languages OR just a string (monolingual).
 * @param {string} lang: The language for which the string should be returned.
 * @returns The proper string for the specified lang (or if the input is a string, just returns the input)
 */
export function parseTranslationNode(node, lang = null, defaultLanguage) {
  if (node == null) {
    return "";
  }
  if (typeof node === "string") {
    return node;
  }
  if (node.hasOwnProperty(lang)) {
    const localizedText = node[lang];
    // if the text is empty for the current lang we do not return but try to use the default language
    if (localizedText !== null && localizedText !== "") {
      return node[lang];
    }
  }
  if (node.hasOwnProperty(defaultLanguage)) {
    const localizedText = node[defaultLanguage];
    if (localizedText === null || localizedText === "") {
      throw Error(
        `The localized text turned out empty for the specified language ${lang} and the default language ${DEFAULT_LANGUAGE} in node ${JSON.stringify(
          node
        )}`
      );
    }
    return localizedText;
  }

  console.warn(`Translation Warning: The passed node with the language ${lang} was not a string but did not include
  the lang tag. \n
  Node: ${JSON.stringify(node)}`);
  return node;
}

export default useTranslation;
