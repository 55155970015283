exports.components = {
  "component---src-components-text-page-js": () => import("./../../../src/components/TextPage.js" /* webpackChunkName: "component---src-components-text-page-js" */),
  "component---src-features-product-index-js": () => import("./../../../src/features/product/index.js" /* webpackChunkName: "component---src-features-product-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cart-jsx": () => import("./../../../src/pages/cart.jsx" /* webpackChunkName: "component---src-pages-cart-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-raw-materials-js": () => import("./../../../src/pages/rawMaterials.js" /* webpackChunkName: "component---src-pages-raw-materials-js" */),
  "component---src-pages-shop-jsx": () => import("./../../../src/pages/shop.jsx" /* webpackChunkName: "component---src-pages-shop-jsx" */)
}

