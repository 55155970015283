import cssVariables from "./cssVariables";

const globalStyles = {
  ...cssVariables,
  html: {
    scrollBehavior: "smooth",
    fontSize: "clamp(18px, 1.14vw, 36px )", // used as base font size -> 1rem
  },
  "html, body": {
    color: "black",
    maxWidth: "100%",
    hyphens: "auto",
    hyphenateLimitChars: "auto 4",
    fontFamily: "'Basel Grotesk', sans-serif",
  },
  "*": {
    lineHeight: "1.2em",
    textDecorationThickness: "0.06em !important",
    hyphens: "auto",
    hyphenateLimitChars: "auto 4",
  },

  /* LEGAL DISCLAIMER
   *
   * These Fonts are licensed only for use on these domains and their subdomains:
   * www.formfeld.net
   *
   * It is illegal to download or use them on other websites.
   *
   * While the @font-face statements below may be modified by the client, this
   * disclaimer may not be removed.
   *
   * Optimo webfonts are protected by copyright law and provided under license. To modify, alter, translate, convert, decode or reverse engineer in any manner whatsoever, including converting the Font Software into a different format is strictly prohibited. The webfont files are not to be used for anything other than web font use.
   *
   * optimo.ch
   */

  "@font-face": {
    fontFamily: "Basel Grotesk",
    src: `url('/fonts/Basel-Grotesk-Regular.otf') format('opentype'),
          url('/fonts/Basel-Grotesk-Regular.woff') format('woff'),
          url('/fonts/Basel-Grotesk-Regular.woff2') format('woff2')`,
    fontStyle: "normal",
    fontDisplay: "swap",
  },
};

export default globalStyles;

/*
  This will hide the focus indicator if the element receives focus    via the mouse,
  but it will still show up on keyboard focus.

  ".js-focus-visible :focus:not([data-focus-visible-added])": {
    outline: "none",
    boxShadow: "none",
  },
*/
